const filterPricesStatic = {
  HW: {
    US: { base: 349, sales: 349 },
  },
  RP: {
    US: { base: 349, sales: 244 },
  },
  MB: {
    US: { base: 349, sales: 244 },
  },
  BB: {
    US: { base: 349, sales: 349 },
  },
  BS: {
    US: { base: 349, sales: 349 },
  },
  CC: {
    US: { base: 349, sales: 244 },
  },
  OR: {
    US: { base: 349, sales: 349 },
  },
};

export default filterPricesStatic;
